'use client';

import Logo from './Logo';

export default function Header() {
  return (
    <header className='z-30 mt-2 w-full md:mt-5'>
      <div className='mx-auto max-w-6xl px-4 sm:px-6'>
        <div className='relative flex h-14 w-full items-center justify-between gap-3 rounded-2xl bg-slide px-3 overflow-hidden'>
          {/* Site branding */}
          <div className='flex flex-1 items-center justify-center sm:justify-start z-10'>
            <Logo />
            <span className='hidden sm:inline-block ml-auto text-white font-bold'>
              Full site coming soon... &nbsp;
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}
