import Link from 'next/link';
import Image from 'next/image';
import logo from '@/public/images/logo.svg';
import logoDark from '@/public/images/logo-dark.svg';

export default function Logo({ dark }: { dark?: boolean }) {
  return (
    <Link href='/' className='inline-flex shrink-0' aria-label='Coluko'>
      <Image
        src={dark ? logoDark : logo}
        alt='Coluko Logo'
        width={154}
        height={0}
        className='h-8 w-auto'
      />
    </Link>
  );
}
